import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import {
  Box,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import WebsitesServices from '../components/WebsitesServices';
import CustomSoftwareServices from '../components/CustomSoftwareServices';

const Services: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Helmet>
        <title>Services | Bit Blossom Software</title>
        <meta name="description" content="Bit Blossom Software provides heart-centered professional services around software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions." />
      </Helmet>

      <AppBarWithLogo
        logoAlt="Bit Blossom Software Logo"
        logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
        appName="Bit Blossom Software"
        useDark={false}
      />

      {/* 
        Outer Box with the existing radial background from your Home page.
        This covers the entire page. 
      */}
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        sx={{
          minHeight: '100vh',
          color: theme.palette.common.white,
          backgroundColor: theme.palette.common.white,
          position: 'relative',
          overflow: 'hidden',
          alignContent: 'center',
          // Padding so we don't butt content up against the edges
          pt: 8,
          px: 4,
          pb: 4,
        }}
      >
        {/*
          HERO / INTRO
        */}
        <Box sx={{ width: '100%', mt: isDesktop ? 8 : 0, mb: 6 }}>
          <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
            <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 2, color: theme.palette.primary.light }}>
              Our Services
            </Typography>
          </Slide>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ flex: 1 }}>
              <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                <Typography variant="h2" sx={{ mt: 2, maxWidth: { xs: '100%', md: 800 }, fontSize: { xs: '1.5rem', md: '2rem' }, color: theme.palette.secondary.dark }}>
                  At Bit Blossom Software, we offer a range of tailored services designed
                  to empower your business with innovative, reliable, and scalable solutions.
                  From websites to custom software, consulting, and ongoing support,
                  we’ve got you covered.
                </Typography>
              </Slide>
            </Box>
            <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
              <Box
                component="img"
                sx={{
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: '100%', md: 250 },
                  borderRadius: 1,
                  ml: 0,
                  mr: { xs: 0, md: 0 },
                  opacity: { xs: 0.5, md: 1 },
                  width: 'auto',
                  height: 'auto',
                }}
                alt="Laptop with colors"
                src="laptop.png"
              />
            </Slide>
          </Box>
        </Box>
        <WebsitesServices />
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', mb: 6 }} />
        <CustomSoftwareServices />
        <BottomNav useDark={false} />
      </Box>
    </>
  );
};

export default Services;
