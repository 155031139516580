import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';

// Define the props type
interface BlogItemProps {
  title: string;
  picture: string;
  abstract: string;
  url: string;
  bottom_boarder: boolean;
}

// Create the BlogItem component
const BlogItem: React.FC<BlogItemProps> = ({ title, picture, abstract, url, bottom_boarder }) => {
  const theme = useTheme();

  // Optional click handler if you use React Router or similar
  const handleReadHere = () => {
    window.location.href = url;
  };

  return (
    // Subtle hover scaling
    <article>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'flex-start',
          mb: 2,
          pb: 2,
          color: theme.palette.common.white,
        }}
      >
        {/* Blog Image */}
        <Box
          component="img"
          src={picture}
          alt={title}
          sx={{
            width: { xs: '100%', md: 150 },
            height: { xs: 150, md: 'auto' },
            objectFit: 'cover',
            borderRadius: 2,
            mr: { xs: 0, md: 2 },
            mb: { xs: 2, md: 0 },
          }}
        />

        {/* Text content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 2, marginRight: 2 }}>
          <Box>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {abstract}
            </Typography>
          </Box>

          {/* "Read Here" Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="text"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{ fontSize: 16, color: theme.palette.secondary.main }}
                />
              }
              sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
              onClick={handleReadHere}
            >
              <Typography
                variant="body1"
                sx={{ color: theme.palette.common.white, textAlign: 'right' }}
              >
                Read Here
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      {bottom_boarder && (
        <Box
          sx={{
            borderTop: '1px solid',
            borderColor: 'rgba(255, 255, 255, 0.3)',
            mt: 4,
            mb: 4,
          }}
        />
      )}
    </article>
  );
};

export default BlogItem;