import React, { useRef, useState } from 'react';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import {
    Box, Typography, TextField, Button, MenuItem,
    CircularProgress, FormControl, InputLabel, Select
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion, useInView } from 'framer-motion';
import Confetti from 'react-confetti';
import ReactGA from 'react-ga4'; // Import react-ga4
import { ServiceDropDownItems } from '../data/Services';
import { Helmet } from 'react-helmet-async';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
};

const Contact: React.FC = () => {
    const theme = useTheme();

    const formRef = useRef(null);
    const isFormInView = useInView(formRef, { once: true });
    const [formData, setFormData] = useState({ name: '', email: '', message: '', service: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [service, setService] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate fields
        if (name === 'name' && !value) {
            setNameError('Name is required');
        } else {
            setNameError('');
        }

        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value) {
                setEmailError('Email is required');
            } else if (!emailRegex.test(value)) {
                setEmailError('Invalid email address');
            } else {
                setEmailError('');
            }
        }

        if (name === 'message' && !value) {
            setMessageError('Message is required');
        } else {
            setMessageError('');
        }
    };

    const isFormValid = () => {
        const { name, email, message } = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return name && email && message && emailRegex.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Proceed to submit the form
        try {
            const response = await fetch('/api/contact_us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormData({ name: '', email: '', message: '', service: '' });
                setSuccess('Thank you for reaching out!');
                setShowConfetti(true);
            } else {
                const errorData = await response.text();
                setError(errorData);
                // Log to Google Analytics
                ReactGA.event({
                    category: 'Contact Form',
                    action: 'Submit Error',
                    label: errorData
                });
            }
        } catch (err) {
            setError('An error occurred while sending the message.');
            // Log to Google Analytics
            ReactGA.event({
                category: 'Contact Form',
                action: 'Submit Error',
                label: 'Network Error',
            });
        } finally {
            setLoading(false);
        }
    };

    const FormStyling = {
        '& .MuiInputLabel-root': { color: theme.palette.common.white },
        '& .MuiInputLabel-root.Mui-focused': { color: theme.palette.common.white },
        '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: theme.palette.common.white },
            '&:hover fieldset': { borderColor: theme.palette.common.white },
            '&.Mui-focused fieldset': { borderColor: theme.palette.common.white },
        },
        '& .MuiInputBase-input::placeholder': { color: theme.palette.common.white },
        '& .MuiInputBase-input': {
            color: theme.palette.common.white, caretColor: theme.palette.common.white,
            '-webkit-text-fill-color': theme.palette.common.white
        },
        color: theme.palette.common.white
    };

    return (
        <>
            <Helmet>
                <title>Contact Us | Bit Blossom Software</title>
                <meta name="description" content="Bit Blossom Software provides heart-centered professional services around software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions." />
            </Helmet>
            {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_White.PNG`}
                appName="Bit Blossom Software"
                useDark={true}
            />
            <Box
                display="flex"
                flexDirection="column"
                paddingTop={0}
                paddingLeft={8}
                paddingRight={8}
                paddingBottom={0}
                sx={{
                    background:
                        `radial-gradient(circle at 80.2734375% 100%, ${theme.palette.secondary.main} 0%, 17.5%, rgba(21, 16, 37, 0) 35%),
             radial-gradient(circle at 98.46028645833333% 68.671875%, ${theme.palette.primary.main} 0%, 20%, rgba(73, 0, 148, 0) 40%),
             radial-gradient(circle at 0% 0%, ${theme.palette.primary.main}  0%, 25%, rgba(73, 0, 148, 0) 50%),
             radial-gradient(circle at 48.9013671875% 49.521484375%, #151025 0%, 100%, rgba(21, 16, 37, 0) 100%)`,
                    color: theme.palette.common.white,
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: '100vh',
                }}
            >
                <Box sx={{ width: '100%', mt: 10 }}>
                    <Typography variant="h2" sx={{ textAlign: 'left', fontWeight: 400 }}>
                        Contact Us
                    </Typography>
                    <Typography variant="h4" sx={{ textAlign: 'left', fontWeight: 100, mt: 2 }}>
                        We'd love to hear from you
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderTop: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                        mt: 4,
                        mb: 0,
                    }}
                />
                <Box sx={{ mt: 4, width: '100%', mb: 4 }}>
                    <motion.div
                        ref={formRef}
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate={isFormInView ? "visible" : "hidden"}
                        transition={{ duration: 0.8 }}
                    >
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            autoComplete='off'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: { xs: '90%', md: '50%' },
                                background: 'rgba(255, 255, 255, 0.1)',
                                padding: 4,
                                borderRadius: 2,
                                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                mx: 'auto',
                                position: 'relative',
                            }}
                        >
                            {loading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        borderRadius: 2,
                                        zIndex: 1,
                                    }}
                                >
                                    <CircularProgress color="secondary" />
                                </Box>
                            )}
                            {!loading && (
                                <>
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            required
                                            label="Name"
                                            name="name"
                                            variant="outlined"
                                            value={formData.name}
                                            onChange={handleChange}
                                            error={!!nameError}
                                            helperText={nameError}
                                            sx={{ ...FormStyling }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            required
                                            label="Email"
                                            name="email"
                                            variant="outlined"
                                            value={formData.email}
                                            onChange={handleChange}
                                            error={!!emailError}
                                            helperText={emailError}
                                            inputProps={{
                                                type: "email",
                                            }}
                                            sx={{ ...FormStyling }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" variant="outlined">
                                        <InputLabel
                                            id="service-select-label"
                                            sx={{
                                                color: theme.palette.common.white,
                                                '&.Mui-focused': { color: theme.palette.common.white },
                                                zIndex: 1, // Ensure label is above the outline
                                            }}
                                        >
                                            Service*
                                        </InputLabel>
                                        <Select
                                            label="Service*"
                                            id="service-select"
                                            value={service}
                                            onChange={(e) => {
                                                setService(e.target.value);
                                                setFormData({ ...formData, 'service': e.target.value });
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Set the background color of the dropdown menu
                                                        color: theme.palette.common.white,
                                                    },
                                                },
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': { color: theme.palette.common.white, backgroundColor: 'transparent' },
                                                '& .MuiSvgIcon-root': { color: theme.palette.common.white },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': { borderColor: theme.palette.common.white },
                                                    '&:hover fieldset': { borderColor: theme.palette.common.white },
                                                    '&.Mui-focused fieldset': { borderColor: theme.palette.common.white },
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: theme.palette.common.white,
                                                },
                                                '& .MuiSelect-select': {
                                                    backgroundColor: 'transparent',
                                                },
                                                '& .MuiSelect-select:focus': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                        >
                                            {ServiceDropDownItems.map((item, index) => (
                                                <MenuItem key={index} value={item} sx={{ color: theme.palette.common.white }}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            required
                                            label="Message"
                                            name="message"
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            value={formData.message}
                                            onChange={handleChange}
                                            error={!!messageError}
                                            helperText={messageError}
                                            sx={{ ...FormStyling }}
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            mt: 2,
                                            '&.Mui-disabled': {
                                                backgroundColor: theme.palette.grey[500], // Change this to your desired color
                                                color: 'grey',
                                            }
                                        }}
                                        disabled={!isFormValid() || loading}
                                    >
                                        {loading ? 'Sending...' : 'Send Message'}
                                    </Button>
                                    {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                                    {success && <Typography color="success" sx={{ mt: 2 }}>{success}</Typography>}
                                </>
                            )}
                        </Box>
                        {!loading && success && (
                            <Typography variant="h5" sx={{ textAlign: 'center', mt: 4 }}>
                                Thank you for reaching out! We will get back to you soon.
                            </Typography>
                        )}
                    </motion.div>
                </Box>
                <BottomNav useDark={true} />
            </Box>
        </>
    );
};

export default Contact;