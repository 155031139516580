import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import { FaMediumM } from 'react-icons/fa';

type MediumIconWrapperProps = {
  sx?: React.CSSProperties; // Type for the sx prop
};

const MediumIconWrapper: React.FC<MediumIconWrapperProps> = ({ sx }) => {
  return <FaMediumM style={sx} />;
};

export type SocialMediaLinks = {
  href: string;
  icon: React.ComponentType<any>;
  id: number;
};

export const SocialMedia: SocialMediaLinks[] = [
  {
    id: 1,
    href: 'https://www.facebook.com/share/1D7Gs6mkDP/',
    icon: FacebookIcon,
  },
  {
    id: 2,
    href: 'https://www.linkedin.com/in/sydney-boschert-67074749',
    icon: LinkedInIcon,
  },
  {
    id: 3,
    href: 'https://medium.com/@bitblossomsoftware',
    icon: MediumIconWrapper, // Use the wrapper component here
  },
  {
    id: 4,
    href: 'https://www.instagram.com/bitblossomsoftware/',
    icon: InstagramIcon,
  },
  {
    id: 5,
    href: 'https://github.com/sydneyboschert',
    icon: GitHubIcon,
  },
];
