import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, Grid, ButtonBase, useMediaQuery } from '@mui/material';
import { motion, useInView } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Services } from '../data/Services';
import ServiceItem from '../components/ServiceItem';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
};

const Home: React.FC = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const empoweringRef = useRef(null);
    const servicesRef = useRef(null);
    const loveNotesRef = useRef(null);
    const ctaRef = useRef(null);

    const isEmpoweringInView = useInView(empoweringRef, { once: true });
    const isServicesInView = useInView(servicesRef, { once: true });
    const isLoveNotesInView = useInView(loveNotesRef, { once: true });
    const isCtaInView = useInView(ctaRef, { once: true });

    return (
        <>
            <Helmet>
                <title>Bit Blossom Software</title>
                <meta name="description" content="Bit Blossom Software provides heart-centered professional services around software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions." />
            </Helmet>
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_White.PNG`}
                appName="Bit Blossom Software"
                useDark={true}
            />
            <Box
                component="main"
                display="flex"
                flexDirection="column"
                paddingTop={8}
                paddingLeft={8}
                paddingRight={8}
                paddingBottom={0}
                sx={{
                    background:
                        `radial-gradient(circle at 80.2734375% 100%, ${theme.palette.secondary.main} 0%, 17.5%, rgba(21, 16, 37, 0) 35%),
                       radial-gradient(circle at 98.46028645833333% 68.671875%, ${theme.palette.primary.main} 0%, 20%, rgba(73, 0, 148, 0) 40%), 
                       radial-gradient(circle at 0% 0%, ${theme.palette.primary.main}  0%, 25%, rgba(73, 0, 148, 0) 50%), Top left
                       radial-gradient(circle at 48.9013671875% 49.521484375%, #151025 0%, 100%, rgba(21, 16, 37, 0) 100%)`,
                    color: theme.palette.common.white,
                    position: 'relative',
                    overflow: 'hidden',
                    alignContent: 'center',
                }}
            >
                <Box sx={{ width: '100%', mt: isDesktop ? 25 : 0 }}>
                    <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <Typography variant="h1" sx={{ textAlign: 'left', fontWeight: 400 }}>
                            Technology Professional Services with Heart, Partnership with Purpose
                        </Typography>
                    </Slide>
                    <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                            <Typography variant="h2" sx={{ textAlign: 'left', fontWeight: 100 }}>
                                Empowering businesses with compassionate, tailored technology solutions.
                            </Typography>
                        </Box>
                    </Slide>
                    <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 4, pr: 2 }}>
                            <ButtonBase
                                sx={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                    const element = document.getElementById('services-section');
                                    if (element) {
                                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    }
                                }}
                            >
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: 16, color: theme.palette.secondary.main }} />}
                                    sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                                    onClick={() => {
                                        const element = document.getElementById('empowering-section');
                                        if (element) {
                                            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                                            window.scrollBy({ top: element.getBoundingClientRect().top - window.innerHeight / 4, behavior: 'smooth' });
                                        }
                                    }}
                                >
                                    <Typography variant="body1" sx={{ color: theme.palette.common.white, textAlign: 'right' }}>
                                        See What We Do
                                    </Typography>
                                </Button>
                            </ButtonBase>
                        </Box>
                    </Slide>
                </Box>

                <Box ref={empoweringRef} sx={{ borderTop: '0px solid', borderColor: 'rgba(255, 255, 255, 0.3)', mt: isDesktop ? 25 : 0, mb: 4 }} id="empowering-section">
                    <motion.div
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate={isEmpoweringInView ? "visible" : "hidden"}
                        transition={{ duration: 0.8 }}
                    >
                        <Typography variant="h1" sx={{ mt: 4, mb: 4 }}>
                            Empowering businesses with purpose
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    src={`Hearts.png`}
                                    alt="Hearts"
                                    sx={{ width: '50%', height: 'auto', borderRadius: 8 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>
                                        Empowering businesses with purpose
                                    </Typography>
                                    <Typography variant="body1">
                                        We believe in more than just technology—we’re here to create solutions
                                        that align with your vision and values.

                                        Every project is a partnership built on trust, empathy, and collaboration.

                                        Here, technology meets empathy, helping you feel supported, informed, and empowered to achieve your goals.
                                        With a focus on your success, we’re committed to helping you thrive by delivering purposeful,
                                        heart-centered tech solutions that grow with your business.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </motion.div>
                </Box>

                <Box ref={servicesRef} sx={{ borderTop: '1px solid', borderColor: 'rgba(255, 255, 255, 0.3)', mt: 4, mb: 4, alignContent: 'center' }} id="services-section">
                    <motion.div
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate={isServicesInView ? "visible" : "hidden"}
                        transition={{ duration: 0.8 }}
                    >
                        <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>
                            Services
                        </Typography>
                        <Box style={{
                            display: 'grid',
                            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : '1fr',
                            gap: '20px',
                            justifyContent: 'start',
                        }}>
                            {Services.map((service) => (
                                <ServiceItem
                                    title={service.title}
                                    icon={service.icon}
                                    description={service.description}
                                    min_height={300}
                                    key={service.id}
                                />
                            ))}
                        </Box>
                    </motion.div>
                </Box>

                <Box ref={loveNotesRef} sx={{ borderTop: '1px solid', borderColor: 'rgba(255, 255, 255, 0.3)', mt: 4, mb: 4 }}>
                    <motion.div
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate={isLoveNotesInView ? "visible" : "hidden"}
                        transition={{ duration: 0.8 }}
                    >
                        <Typography variant="h1" sx={{ mt: 4, mb: 4 }}>
                            Love Notes
                        </Typography>
                        <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>
                            Client Reviews Coming Soon!
                        </Typography>
                    </motion.div>
                </Box>

                <Grid ref={ctaRef} item xs={12} md={4}>
                    <motion.div
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate={isCtaInView ? "visible" : "hidden"}
                        transition={{ duration: 0.8 }}
                    >
                        <Box
                            display="flex"
                            flexDirection={{ xs: 'column', md: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                mt: 4,
                                mb: 4,
                                p: 4,
                                borderRadius: 2,
                                background: 'rgba(255, 255, 255, 0.1)',
                                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            <Box sx={{ width: { xs: '100%', md: '60%' }, mt: 4, mb: 4 }}>
                                <Typography variant="h3" color="theme.palette.common.white" align='left'>
                                    Ready to Transform Your Business
                                </Typography>
                                <Typography variant="h5" gutterBottom color="theme.palette.common.white">
                                    With Heart-Centered Software Solutions
                                </Typography>
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: '20%' }, display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, mt: { xs: 2, md: 0 } }}>
                                <ArrowForwardIosIcon sx={{ fontSize: 100, color: theme.palette.secondary.main }} />
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: '30%' }, display: 'flex', justifyContent: 'center', mt: { xs: 2, md: 0 } }}>
                                <Typography variant="h5" gutterBottom color="theme.palette.common.white">
                                    If so, your journey starts here
                                </Typography>
                            </Box>
                        </Box>
                    </motion.div>
                </Grid>
                <BottomNav useDark={true}/>
            </Box>
        </>
    );
};

export default Home;