import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

// Define the props type
interface ServiceItemProps {
    title: string,
    icon: React.ReactElement,
    description: string,
    min_height?: number,
}

// Create the BlogItem component
const ServiceItem: React.FC<ServiceItemProps> = ({ title, icon, description, min_height }) => {
    return (
        <motion.div whileHover={{ scale: 1.05 }} style={{ flex: 1, display: 'flex' }}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    p: 4,
                    borderRadius: 2,
                    background: 'rgba(255, 255, 255, 0.1)',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    transition: 'transform 0.3s ease-in-out',
                    flex: 1,
                    minHeight: min_height,
                }}
            >
                <Box>
                    <Typography variant="h4" color='White' align='center'>
                        {title}
                    </Typography>
                </Box>
                <Box>
                    {icon}
                </Box>
                <Box>
                    <Typography variant="subtitle1" gutterBottom color='White' align='center'>
                        {description}
                    </Typography>
                </Box>
            </Box>
        </motion.div>
    );
};

export default ServiceItem;
