import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { ListItemText } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

interface AppBarWithLogoProps {
  logoAlt: string;
  logoSrc: string;
  appName: string;
  useDark: boolean;
}

const AppBarWithLogo: React.FC<AppBarWithLogoProps> = ({ logoAlt, logoSrc, appName,useDark }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pages = ['About', 'Services', 'Blog', 'Contact'];
  const trigger = useScrollTrigger();
  const style = useDark ? {} : { background: 'white', boxShadow: 'none', borderBottom: '1px solid #EFECE5' };


  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <Box
            component="img"
            sx={{
              height: 55,
              margin: 1,
            }}
            alt="Bit Blossom Software Badge"
            src="BitBlossom_Logo_Badge.png"
          />
      <List>
        {pages.map((page, index) => (
          <ListItemButton key={`btn_${page}_${index}`} component={Link} to={`/${page.toLowerCase()}`}>
            <ListItemText key={`txt_${page}_${index}`} primary={page} sx={{color: theme.palette.primary.dark }}/>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
  

  return (
    <Slide in={!trigger}>
    <AppBar position="sticky" style={{...style}}>
      <Toolbar>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box
            component="img"
            sx={{
              height: 55,
              margin: 1,
            }}
            alt={logoAlt}
            src={logoSrc}
          />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        {isMobile ? (
          <>
            <IconButton
              color={useDark ? "inherit" : "primary"}
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            {pages.map((page) => (
              <Link key={`typography_${page}`} to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography
                  key={`typography_${page}`}
                  variant="h6"
                  component="div"
                  fontWeight={useDark ? "":"bold"}
                  sx={{ margin: theme.spacing(1), color: useDark ? theme.palette.common.white : theme.palette.primary.dark }}
                >
                  {page}
                </Typography>
              </Link>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
    </Slide>
  );
};

export default AppBarWithLogo;