import React, { useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextTerminal from './TextTerminal';
import FormButton from './FormButton';
import ContactUsPopupForm from './ContactUsPopupForm';
import CurvedBackground from './CurvedBackground';
import { CustomServicePackages, CustomServiceTextInfo } from '../data/Services';


export default function CustomSoftwareServices() {
    const theme = useTheme();
    const [showForm, setShowForm] = useState(false);

    // we need the boxRef since the parent containers fill the whole page we need to maintain margins
    return (
        <CurvedBackground color={theme.palette.custom.tertiary}>
            <Typography variant="h1" gutterBottom sx={{ mt: 4, color: theme.palette.secondary.light, fontWeight: 'bold' }}>
                Tailored IT & Software Solutions
            </Typography>
            <Box sx={{ height: '10px', ml: 1 }}>
                <TextTerminal words={CustomServiceTextInfo} />
            </Box>
            <Divider sx={{ borderBottom: 1, width: '100%', mt: 8 }} />
            <Grid container spacing={4} sx={{ mt: 4 }}>
                {CustomServicePackages.map((packageInfo, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2, lineHeight: 1.2, color: theme.palette.secondary.light }}>
                            {packageInfo.title}
                        </Typography>

                        <Typography variant="h5" sx={{ mb: 1 }}>
                            Starting Investment <span style={{ fontWeight: 'bold' }}>{packageInfo.price}</span>
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            {packageInfo.description}
                        </Typography>
                        <List dense sx={{ padding: 0, '& .MuiListItem-root': { paddingTop: 0, paddingBottom: 0 } }}>
                            {packageInfo.features.map((feature, idx) => (
                                <ListItem key={idx}>
                                    <ListItemText primary={`✦ ${feature}`} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                ))}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <FormButton onClick={() => setShowForm(true)} Text="Get Started" />
                </Grid>
                <ContactUsPopupForm show={showForm} onClose={() => setShowForm(false)} />
            </Grid>
        </CurvedBackground >
    )
}