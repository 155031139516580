import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography } from '@mui/material';
import { Blogs } from '../data/Blogs';
import BlogItem from '../components/BlogItem';
import { useTheme } from '@mui/material/styles';

const Blog: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>Blog | Bit Blossom Software</title>
                <meta name="description" content="Read the latest insights, updates, and stories from Bit Blossom Software in Fargo, ND. Stay informed about our software solutions and technology trends." />
            </Helmet>
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_White.PNG`}
                appName="Bit Blossom Software"
                useDark={true}
            />
            <Box
                component="main"
                display="flex"
                flexDirection="column"
                paddingTop={0}
                paddingLeft={8}
                paddingRight={8}
                paddingBottom={0}
                sx={{
                    background:
                        `radial-gradient(circle at 80.2734375% 100%, ${theme.palette.secondary.main} 0%, 17.5%, rgba(21, 16, 37, 0) 35%),
                         radial-gradient(circle at 98.46028645833333% 68.671875%, ${theme.palette.primary.main} 0%, 20%, rgba(73, 0, 148, 0) 40%),
                         radial-gradient(circle at 0% 0%, ${theme.palette.primary.main}  0%, 25%, rgba(73, 0, 148, 0) 50%),
                         radial-gradient(circle at 48.9013671875% 49.521484375%, #151025 0%, 100%, rgba(21, 16, 37, 0) 100%)`,
                    color: theme.palette.common.white,
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: '100vh',
                }}
            >
                <Box sx={{ width: '100%', mt: 10 }}>
                    <Typography variant="h1" sx={{ textAlign: 'left', fontWeight: 400 }}>
                        Our Blog
                    </Typography>
                    <Typography variant="h2" sx={{ textAlign: 'left', fontWeight: 100, mt: 2 }}>
                        Insights, Updates & Stories
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderTop: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                        mt: 4,
                        mb: 0,
                    }}
                />
                <Box sx={{ mt: 4, width: '100%' }}>
                    {Blogs.map((blog, index) => (
                        <BlogItem
                            key={blog.id}
                            title={blog.title}
                            picture={blog.image}
                            abstract={blog.abstract}
                            url={blog.url}
                            bottom_boarder={index === Blogs.length - 1 ? false : true}
                        />
                    ))}
                </Box>
                <BottomNav useDark={true}/>
            </Box>
        </>
    );
};

export default Blog;