import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import ReactGA from 'react-ga4';
import { CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      tertiary: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      tertiary?: string;
    };
  }
}

const App: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5C007C' // #Royal_Violet
      },
      secondary: {
        main: '#CB77FF', //Fuschia
      },
      custom: {
        tertiary: '#004752', // Evergreen Third primary color
      },
      common: {
        //white: '#EFECE5',
        white: '#FAF8FE',
        black: '#000000',
      },
    },
    typography: {
      fontFamily: 'DM Sans, sans-serif',
      h1: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 400, // Regular
        fontSize: isMobile ? '1.5rem' : '3.75rem', // Adjust font size for mobile
      },
      h2: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 800, // Extra bold
        fontSize: isMobile ? '1rem' : '2.12rem', // Adjust font size for mobile
      },
      h3: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 700, // Bold
        fontSize: isMobile ? '0.875rem' : '1.75rem', // Adjust font size for mobile
      },
      h4: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 600, // Semi-bold
        fontSize: isMobile ? '0.75rem' : '1.5rem', // Adjust font size for mobile
      },
      h5: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 500, // Medium
        fontSize: isMobile ? '0.625rem' : '1.25rem', // Adjust font size for mobile
      },
      h6: {
        fontFamily: 'DM Sans, sans-serif',
        fontWeight: 400, // Regular
        fontSize: isMobile ? '0.5rem' : '1rem', // Adjust font size for mobile
      },
      body1: {
        fontFamily: 'DM Sans, sans-serif',
        fontSize: isMobile ? '0.875rem' : '1rem', // Adjust font size for mobile
      },
    },
  });

  const TRACKING_ID = "G-VLYP8LFK6Y";
  ReactGA.initialize(TRACKING_ID);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;