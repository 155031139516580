import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Typography, IconButton, useTheme, FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactGA from 'react-ga4'; 
import { ServiceDropDownItems}  from '../data/Services';

interface ContactUsPopupFormProps {
    show: boolean;
    onClose: () => void;
}

const ContactUsPopupForm: React.FC<ContactUsPopupFormProps> = ({ show, onClose }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState({ name: '', email: '', message: '', service: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [service, setService] = useState('');
    const [serviceError, setServiceError] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate fields
        if (name === 'name' && !value) {
            setNameError('Name is required');
        } else {
            setNameError('');
        }

        if (name === 'service'){
            if (!value) {
                setServiceError('Service is required');
            } else {
                setServiceError('');
            }
        }

        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value) {
                setEmailError('Email is required');
            } else if (!emailRegex.test(value)) {
                setEmailError('Invalid email address');
            } else {
                setEmailError('');
            }
        }

        if (name === 'message' && !value) {
            setMessageError('Message is required');
        } else {
            setMessageError('');
        }
    };

    const isFormValid = () => {
        const { name, email, message } = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return name && email && message && emailRegex.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');


        // Proceed to submit the form
        try {
            const response = await fetch('/api/contact_us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormData({ name: '', email: '', message: '',service: '' });
                setSuccess('Thank you for reaching out!');
            } else {
                const errorData = await response.text();
                setError(errorData);
                // Log to Google Analytics
                ReactGA.event({
                    category: 'Contact Form',
                    action: 'Submit Error',
                    label: errorData
                });
            }
        } catch (err) {
            setError('An error occurred while sending the message.');
            // Log to Google Analytics
            ReactGA.event({
                category: 'Contact Form',
                action: 'Submit Error',
                label: 'Network Error',
            });
        } finally {
            setLoading(false);
        }
    };

    const FormStyling = {
        '& .MuiInputLabel-root': { color: theme.palette.primary.main },
        '& .MuiInputLabel-root.Mui-focused': { color: theme.palette.primary.main },
        '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: theme.palette.primary.main },
            '&:hover fieldset': { borderColor: theme.palette.primary.main },
            '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
        },
        '& .MuiInputBase-input::placeholder': { color: theme.palette.primary.main },
        '& .MuiInputBase-input': { color: theme.palette.primary.main, caretColor: theme.palette.primary.main, 
            '-webkit-text-fill-color': theme.palette.primary.main },
        color: theme.palette.primary.main
    };

    return (
        <Dialog
            open={show}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: 10, // Rounded corners
                    maxWidth: '600px', // Max width of the dialog
                    width: '90%', // Width of the dialog
                    margin: 'auto', // Center the dialog
                }
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                paddingTop={0}
                paddingLeft={1}
                paddingRight={1}
                paddingBottom={0}
                sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.common.white,
                    position: 'relative',
                    borderRadius: 1,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box
                        component="img"
                        sx={{
                            height: 55,
                            margin: 1,
                        }}
                        alt="Bit Blossom Software Badge"
                        src="BitBlossom_Logo_Hztl_cropped.png"
                    />
                    <IconButton edge="end" color="primary" onClick={onClose} aria-label="close" style={{ paddingRight: 20 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ width: '100%', mt: 0 }}>
                    <Typography variant="h2" sx={{ textAlign: 'left', fontWeight: 100, mt: 2, color: theme.palette.primary.main }}>
                        We'd love to hear from you!
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderTop: '1px solid',
                        borderColor: 'rgba(255, 255, 255, 0.3)',
                        mt: 4,
                        mb: 0,
                    }}
                />
                <Box sx={{ width: '100%', mb: 4 }}>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <FormControl fullWidth margin="normal">
                            <TextField
                                required
                                label="Name"
                                name="name"
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                error={!!nameError}
                                helperText={nameError}
                                sx={{ ...FormStyling }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                required
                                label="Email"
                                name="email"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!emailError}
                                helperText={emailError}
                                inputProps={{
                                    type: "email",
                                }}
                                sx={{ ...FormStyling }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal" variant="outlined">
                            <InputLabel
                                id="service-select-label"
                                sx={{
                                    color: theme.palette.primary.main,
                                    '&.Mui-focused': { color: theme.palette.primary.main },
                                    zIndex: 1, // Ensure label is above the outline
                                }}
                            >
                                Service*
                            </InputLabel>
                            <Select
                                label="Service*"
                                id="service-select"
                                value={service}
                                error={!!serviceError}
                                onChange={(e) => {
                                    setService(e.target.value);
                                    setFormData({ ...formData, 'service': e.target.value });
                                }}
                                sx={{
                                    '& .MuiInputBase-input': { color: theme.palette.primary.main },
                                    '& .MuiSvgIcon-root': { color: theme.palette.primary.main },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: theme.palette.primary.main },
                                        '&:hover fieldset': { borderColor: theme.palette.primary.main },
                                        '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                }}
                            >
                                {ServiceDropDownItems.map((item, index) => (
                                    <MenuItem key={index} value={item} sx={{ color: theme.palette.primary.main }}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                required
                                label="Message"
                                name="message"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleChange}
                                error={!!messageError}
                                helperText={messageError}
                                sx={{ ...FormStyling }}
                            />
                        </FormControl>
                        <Box display="flex" justifyContent="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                    mt: 2,
                                    '&.Mui-disabled': {
                                        backgroundColor: theme.palette.grey[500],
                                        color: 'grey',
                                    },
                                }}
                                disabled={!isFormValid() || loading}
                            >
                                {loading ? 'Sending...' : 'Send Message'}
                            </Button>
                        </Box>
                        {error && <Typography color="error" sx={{ mt: 2,textAlign:"center"  }}>{error}</Typography>}
                        {success && <Typography color="success" sx={{ mt: 2,textAlign:"center" }}>{success}</Typography>}
                    </Box>
                    {!loading && success && (
                        <Typography variant="h5" sx={{ textAlign: 'center', mt: 4 }}>
                            Thank you for reaching out! We will get back to you soon.
                        </Typography>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
}

export default ContactUsPopupForm;