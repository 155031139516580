import React from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, ButtonBase, useTheme } from '@mui/material';

interface FormButtonProps {
    onClick: () => void;
    Text: string;
}

const FormButton: React.FC<FormButtonProps> = ({ onClick, Text }) => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 4, pr: 2 }}>
            <ButtonBase
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={onClick}
            >
                <Button
                    variant="text"
                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: 16, color: theme.palette.secondary.main }} />}
                    sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                    onClick={onClick}
                >
                    <Typography variant="body1" sx={{ color: theme.palette.common.white, textAlign: 'right' }}>
                        {Text}
                    </Typography>
                </Button>
            </ButtonBase>
        </Box>
    );
}

export default FormButton;