export type Blog = {
    title: string;
    abstract: string;
    image: string;
    id: number;
    url: string;
    alt: string; 
};

export const Blogs: Blog[] = [
    {
        title: "Building Subscription Management with Kajabi Webhooks: Lessons Learned",
        abstract: "Learn how to integrate Kajabi’s Offer Purchase and Payment Complete webhooks into a subscription-based web app, tackle unexpected complexities in data handling, and manage subscription status with best practices and code snippets.",
        image: "kajabi-webhook-integration.png",
        id: 0,
        url: "https://medium.com/@bitblossomsoftware/kajabi-webhook-integration-into-a-3rd-party-app-b29449aaddb5",
        alt: "Building Subscription Management with Kajabi Webhooks"
    },
    {
        title: "Consulting Beyond Code: How Soft Skills Drive Sustainable IT Solutions",
        abstract: "Discover how active listening, understanding client goals, and proactive communication transform short-term IT fixes into enduring solutions, building stronger client relationships and delivering sustainable, long-term value.",
        image: "consulting-beyond-code.png",
        id: 1,
        url: "https://medium.com/@bitblossomsoftware/consulting-beyond-code-how-soft-skills-drive-sustainable-it-solutions-a6d4249c1135",
        alt: "Consulting Beyond Code"
    }
];