import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import {
  Box,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import CurvedBackground from '../components/CurvedBackground';

const About: React.FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Helmet>
        <title>About Us | Bit Blossom Software</title>
        <meta
          name="description"
          content="Bit Blossom Software provides heart-centered software solutions in Fargo, ND. Empowering businesses with compassionate, tailored technology solutions."
        />
      </Helmet>

      <AppBarWithLogo
        logoAlt="Bit Blossom Software Logo"
        logoSrc={`BitBlossom_Logo_Hztl_cropped.png`}
        appName="Bit Blossom Software"
        useDark={false}
      />

      <Box
        component="main"
        display="flex"
        flexDirection="column"
        sx={{
          minHeight: '100vh',
          color: theme.palette.common.white,
          backgroundColor: theme.palette.common.white,
          position: 'relative',
          overflow: 'hidden',
          alignContent: 'center',
          pt: 8,
          px: 4,
          pb: 4,
        }}
      >
        <Box sx={{ width: '100%', mt: isDesktop ? 8 : 0, mb: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <CurvedBackground color={theme.palette.custom.tertiary}>
              <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 2, color: theme.palette.secondary.light }}>
                  About Us
                </Typography>
              </Slide>
              <Typography variant="h2" sx={{ color: theme.palette.secondary.light }}>
                Technology with Heart, Partnership with Purpose
              </Typography>
              <Typography variant="body1" sx={{ color: theme.palette.secondary.light}}>
                At Bit Blossom Software, we believe technology should do more than solve problems; it should empower businesses to grow and thrive. That’s why we blend technical excellence with a heart-centered approach, creating tailored solutions that align with your unique vision and values.
              </Typography>
            </CurvedBackground>
            <Typography variant="h3" sx={{ mt: 4, color: theme.palette.primary.main }}>
              Our Mission
            </Typography>
            <Typography variant="body1" sx={{ mt: 4, color: theme.palette.secondary.dark }}>
              We’re here to build more than just software; we’re here to build trust. Our mission is to help you feel supported, informed, and empowered to achieve your business goals. Every partnership is rooted in collaboration, empathy, and a commitment to your success.
            </Typography>

            <Typography variant="h3" sx={{ mt: 4, color: theme.palette.primary.main }}>
              Our Expertise
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.secondary.dark }}>
              With over a decade of experience in software engineering and user experience, we bring a wealth of knowledge and a proven track record to every project. From custom software development to technology consulting, we specialize in delivering scalable, efficient, and user-friendly solutions that grow with your business. Our team stays at the forefront of emerging technologies to ensure we provide the most effective and innovative solutions for our clients.
            </Typography>

            <Typography variant="h3" sx={{ mt: 4, color: theme.palette.primary.main }}>
              Why Choose Us?
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', pl: 0, mt: 2 }}>
              <Typography component="li" variant="body1" sx={{ mb: 1, color: theme.palette.secondary.dark }}>
                <strong>Tailored Solutions:</strong> No two businesses are the same, and neither are our solutions. We craft technology that fits your needs like a glove.
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1, color: theme.palette.secondary.dark }}>
                <strong>Empathy-Driven Approach:</strong> We listen. We understand. We deliver. Our focus is on creating solutions that resonate with your goals.
              </Typography>
              <Typography component="li" variant="body1" sx={{ mb: 1, color: theme.palette.secondary.dark }}>
                <strong>Ongoing Support:</strong> Our relationship doesn’t end after delivery. We’re here to provide reliable support and maintenance, ensuring your systems operate at their best.
              </Typography>
            </Box>

            <Typography variant="h3" sx={{ mt: 4, color: theme.palette.primary.main }}>
              Meet Sydney Boschert
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.secondary.dark }}>
              Founder of Bit Blossom Software, Sydney is a passionate software engineer with a background in data science, mathematics, and chemistry. Sydney’s career spans roles in UX leadership, full-stack development, and software engineering at industry-leading companies. Known for her ability to translate complex technical challenges into intuitive solutions, Sydney leads Bit Blossom Software with a unique blend of technical expertise and a people-first mindset.
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', mb: 6 }} />
        <BottomNav useDark={false} />
      </Box>
    </>
  );
};

export default About;
