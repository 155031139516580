import React, { useEffect, ReactNode, useState, useRef } from 'react';
import { Box } from '@mui/material';


interface CurvedBackgroundProps {
    children: ReactNode;
    color: string;
};

const CurvedBackground: React.FC<CurvedBackgroundProps> = ({ children, color }) => {
    const boxRef = useRef<HTMLDivElement>(null);
    const [boxWidth, setBoxWidth] = useState(100);
    const [boxMargin, setBoxMargin] = useState({});
    const updateBoxDimensions = () => {
        if (boxRef && boxRef.current) {
            const screenWidth = window.innerWidth;
            const offsetLeft = boxRef.current.offsetLeft;
            setBoxWidth(screenWidth - offsetLeft * 2);
            setBoxMargin({
                marginLeft: offsetLeft,
                marginRight: offsetLeft
            });
        }
    };
    useEffect(() => {
        updateBoxDimensions();
        window.addEventListener('resize', updateBoxDimensions);
        return () => {
            window.removeEventListener('resize', updateBoxDimensions);
        };
    }, []);


    return (<>
        <Box ref={boxRef} sx={{ ml: 0, mr: 0, p: 0, mb: 0, width: '100vw', position: 'relative' }}></Box>
        <Box sx={{ ml: 0, mr: 0, p: 0, mb: 0, width: '100vw', position: 'relative', left: '50%', transform: 'translateX(-50%)' }}>
            <svg
                viewBox="0 0 1440 160" // Adjusted viewBox to show only 50% of the height
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "block", width: '100%', height: '50%', position: 'relative', left: 0, right: 0 }}
            >
                <path
                    fill={color}
                    fillOpacity="1"
                    d="M0,10 C480,20 960,100 1440,100 L1440,200 L0,200 Z"
                ></path>
            </svg>
        </Box>
        <Box sx={{ backgroundColor: color, ml: 0, mr: 0, mt: 0, p: 0, width: '100vw', position: 'relative', left: '50%', transform: 'translateX(-50%)' }}>
            <Box sx={{ width: boxWidth, margin: boxMargin }}>
                {children}
            </Box>
        </Box>
        <Box sx={{ ml: 0, mr: 0, p: 0, mb: 0, mt: 0, width: '100vw', position: 'relative', left: '50%', transform: 'translateX(-50%)' }}>
            <svg
                viewBox="0 0 1440 160" // Adjusted viewBox to show only 50% of the height
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "block", width: '100%', height: '50%', position: 'relative', left: 0, right: 0, transform: 'scale(-1, -1)' }}
            >
                <path
                    fill={color}
                    fillOpacity="1"
                    d="M0,10 C480,20 960,100 1440,100 L1440,200 L0,200 Z"
                ></path>
            </svg>
        </Box>
    </>);
}

export default CurvedBackground;