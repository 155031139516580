import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';

interface TextTerminalProps {
  words: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  pauseTime?: number;
}

const TextTerminal: React.FC<TextTerminalProps> = ({
  words,
  typingSpeed = 75,
  deletingSpeed = 50,
  pauseTime = 1000,
}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[currentWordIndex];
      if (isDeleting) {
        if (displayedText.length > 0) {
          setDisplayedText(displayedText.slice(0, -1));
        } else {
          setIsDeleting(false);
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }
      } else {
        if (displayedText.length < currentWord.length) {
          setDisplayedText(currentWord.slice(0, displayedText.length + 1));
        } else {
          setIsPaused(true);
          setTimeout(() => {
            setIsPaused(false);
            setIsDeleting(true);
          }, pauseTime);
        }
      }
    };

    const typingInterval = setInterval(handleTyping, isPaused ? pauseTime : (isDeleting ? deletingSpeed : typingSpeed));
    return () => clearInterval(typingInterval);
  }, [displayedText, isDeleting, words, currentWordIndex, typingSpeed, deletingSpeed, pauseTime, isPaused]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h4" component="div" sx={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
        {displayedText}
      </Typography>
      <Box
        component="span"
        sx={{
          fontFamily: 'monospace',
          fontSize: 'inherit',
          ml: 1,
          animation: isPaused ? 'blink 1s step-end infinite' : 'none',
          '@keyframes blink': {
            '0%, 100%': { opacity: 1 },
            '50%': { opacity: 0 },
          },
        }}
      >
        |
      </Box>
    </Box>
  );
};

export default TextTerminal;