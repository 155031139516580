import React from 'react';
import { BottomNavigation, Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SocialMedia } from '../data/SocialMediaLinks';


interface BottomNavProps {
  useDark: boolean;
}

const CustomBottomNavigation : React.FC<BottomNavProps> = ({useDark}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const textColor = useDark ? theme.palette.common.white : theme.palette.primary.dark;
  const icon = useDark ? "BitBlossom_Logo_White.PNG" : "BitBlossom_Logo_Hztl_cropped.png";

  return (
    <footer>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.3)',
          backgroundColor: 'transparent',
          paddingLeft: isMobile ? 0 : 8,
          paddingRight: isMobile ? 0 : 8,
          '@media (min-width: 400px)': {
            flexDirection: 'column',
            height: 'auto',
            alignItems: 'center',
          },
        }}
      >
        <Box sx={{
          width: '100%', textAlign: 'center', mt: 4, mb: 2,
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)'
        }}>
          {/* Menu Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 4,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={textColor}
              sx={{ mb: 2 }}
            >
              Menu
            </Typography>
            {['About', 'Services', 'Blog', 'Contact'].map((menu, index) => (
              <Link key={index} href={`/${menu.toLowerCase()}`} color="inherit" underline="none">
                <Typography variant="subtitle1" color={textColor} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                  {menu}
                </Typography>
              </Link>
            ))}
          </Box>

          {/* Contact Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 4,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={textColor}
              sx={{ mb: 2 }}
            >
              Contact
            </Typography>
            <Typography variant="subtitle1" color={textColor}>
              <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              hello@bitblossomsoftware.com
            </Typography>
            <Typography variant="subtitle1" color={textColor}>
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              701-390-3503
            </Typography>
            <Typography variant="subtitle1" color={textColor}>
              <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Fargo, North Dakota
            </Typography>
          </Box>

          {/* Follow Us Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              mb: 4,
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={textColor}
              sx={{ mb: 2 }}
            >
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start', gap: 1 }}>
              {SocialMedia.map((item) => (
                <Link key={item.id} href={item.href} target="_blank" rel="noopener">
                  <item.icon sx={{ color:textColor, fontSize: 40 }} />
                </Link>
              ))}
            </Box>
          </Box>

          {/* Logo and Footer Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Box
              component="img"
              src={icon}
              alt="Bit Blossom Software Logo"
              sx={{
                width: isMobile ? '120px' : '180px',
                height: 'auto',
                mb: isMobile ? 2 : 0,
              }}
            />
            <Typography variant="body1" color={textColor}>
              Built by: Bit Blossom Software
            </Typography>
          </Box>
        </Box>
      </BottomNavigation>
    </footer>
  );
};

export default CustomBottomNavigation;