import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HandshakeIcon from '@mui/icons-material/Handshake';

export const ServiceDropDownItems = [
    "Web Development",
    "Custom Software",
    "IT Consulting",
    "Tech Support",
    "Social Media",
    "Other"
];


export type Service = {
    title: string;
    description: string;
    icon: React.ReactElement;
    id: number;
};

export type WebsitePackage = {
    title: string;
    price: string;
    highlight?: string;
    description: string;
    features: string[];
};

type CustomServicePackage = {
    title: string;
    price: string;
    description: string;
    features: string[];
};


export const Services: Service[] = [
    {
        id: 1,
        title: 'Custom Software Development',
        description: 'We specialize in crafting custom software that perfectly aligns with your business goals and challenges. Whether you need a completely new application, enhancements to existing systems, or seamless integration with other tools, our solutions are designed to be scalable, efficient, and user-friendly.',
        icon: <CodeIcon sx={{ fontSize: 100, color: 'secondary.main', mt: 2 }} />
    },
    {
        id: 2,
        title: 'Technology Consulting',
        description: 'We provide strategic advice and hands-on support to ensure your technology choices align with your business objectives and enhance operational efficiency and helping you navigate the ever-changing tech landscape.',
        icon: <TipsAndUpdatesIcon sx={{ fontSize: 100, color: 'secondary.main', mt: 2 }} />
    },
    {
        id: 3,
        title: 'Reliable Support & Maintenance',
        description: 'We don’t just deliver software and walk away; we’re committed to providing ongoing support and maintenance to ensure your systems continue to operate at their best. From regular updates to troubleshooting and scaling, we’re here to support your long-term success.',
        icon: <HandshakeIcon sx={{ fontSize: 100, color: 'secondary.main', mt: 2 }} />
    }
];


export const WebsitePackages: WebsitePackage[] = [
    {
        title: "One-Page Landing Page",
        price: "$750",
        description: "Perfect for startups, events, or campaigns, this streamlined solution gets your message across beautifully.",
        features: [
            "Import of Full Brand Portfolio into Web Host",
            "Initial Discovery Call + Creative Direction",
            "Domain Set-Up (and/or transfer)",
            "1 Page Site Design",
            "Website Back End (Email, Forms, Automations",
            "Link Set-Up + Implementation",
            "SEO & Keyword Optimization",
            "Copywriting Assistance",
            "Tablet/Mobile Responsive Design",
            "2 Weeks of Post-Launch Support"
        ]
    },
    {
        title: "Portfolio Website – White Glove Custom Design",
        price: "$2,100",
        highlight: "Most Value",
        description: "A professional, multi-page website tailored to showcase your business, services, or portfolio. We combine creativity and strategy to bring your vision to life.",
        features: [
            "Initial Discovery Call + Creative Direction",
            "Import of Full Brand Portfolio into Web Host",
            "Domain Set-Up (and/or transfer)",
            "5 Custom Pages (e.g. Home, About, Services, Blog/Portfolio, Contact)",
            "Website Back End (Email, Forms, Automations)",
            "Link Set-Up + Implementation",
            "SEO & Keyword Optimization",
            "Tablet/Mobile Responsive Design",
            "Copywriting Assistance",
            "2 Weeks of Post-Launch Support"
        ],
    },
    {
        title: "E-Commerce Package",
        price: "$2,800",
        description: "A feature-rich online store to sell your products with ease. Designed for a seamless user experience and optimized for sales.",
        features: [
            "Initial Discovery Call + Creative Direction",
            "Import of Full Brand Portfolio into Web Host",
            "Domain Set-Up (and/or transfer)",
            "5 Custom Pages (including Product & Checkout Pages)",
            "Website Back End (Email, Forms, Automations)",
            "Product Page Design",
            "Branded Checkout Page",
            "Link Set-Up + Implementation",
            "SEO & Keyword Optimization",
            "Tablet/Mobile Responsive Design",
            "Copywriting Assistance",
            "4 Weeks of Post-Launch Support"
        ],
    }
];

export const WebsiteTextInfo : string [] = [
    "Professional websites tailored to your business.",
    "Creative designs that captivate your audience.",
    "Responsive layouts for all devices.",
    "SEO optimized to boost your online presence.",
    "Custom solutions to meet your unique needs."
]


export const CustomServicePackages: CustomServicePackage[] = [
    {
        title: "Custom Software Development",
        price: "$750+",
        description: "Turn your unique ideas into reality with software solutions tailored to your business challenges and goals.",
        features: [
            "From scratch app development or system enhancements",
            "Scalable, user-friendly designs",
            "Seamless integration with your existing tools",
            "Dedicated collaboration to align with your vision",
            "Ongoing support to ensure continued success"
        ]
    },
    {
        title: "Technology Consulting",
        price: "$110/hour",
        description: "Gain clarity and confidence in your tech choices with strategic advice from seasoned experts.",
        features: [
            "Comprehensive business technology assessments",
            "Personalized recommendations for tools and systems",
            "Hands-on support for implementation and adoption",
            "Guidance through the ever-changing tech landscape"
        ]
    },
    {
        title: "Reliable Support & Maintenance",
        price: "$110/hour",
        description: "Keep your systems running smoothly with ongoing assistance and proactive care.",
        features: [
            "Regular updates and troubleshooting",
            "System scaling and optimization",
            "Emergency support when you need it most",
            "Tailored maintenance plans for long-term peace of mind"
        ]
    }
];

export const CustomServiceTextInfo : string [] = [
    "Tailored solutions for your unique business needs.",
    "Mobile and web development that stands out from the competition.",
    "Innovative technology designed specifically for your goals.",
    "Optimized systems built to scale and grow with your business.",
    "Create solutions that enhance efficiency and drive success.",
    "Empower your team with custom tools that align with your vision."
]
